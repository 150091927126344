import {Injectable} from "@angular/core";
import {CacheDatabaseContext, IndexedDbAdapter} from "@juulsgaard/store-service";

@Injectable({providedIn: 'root'})
export class UserScopeDatabase extends CacheDatabaseContext {

  constructor(adapter: IndexedDbAdapter) {
    super(adapter, 'user');
    // Default database to disabled
    this.disable();
  }
}
